@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}
body {
  @apply bg-pami-gray 
  font-roboto 
  max-w-[2500px] 
  m-auto relative;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #397524 #dfe9eb;
}
*::-webkit-scrollbar {
  @apply w-2.5 duration-[1s] ease-[ease];
}
*::-webkit-scrollbar-track {
  @apply bg-[#f0f0f0] rounded-md;
}

*::-webkit-scrollbar-track:hover {
  @apply bg-[#e0e0e0];
}

*::-webkit-scrollbar-track:active {
  @apply bg-[#d0d0d0];
}

*::-webkit-scrollbar-thumb {
  @apply bg-pami-orange-default rounded-md;
}

*::-webkit-scrollbar-thumb:hover {
  @apply bg-[#ff873c];
}

*::-webkit-scrollbar-thumb:active {
  @apply bg-[#c15b1c];
}

/* img {
  @qpply bg-loader;
  background-position: center;
  background-size: 10%;
  background-repeat: no-repeat;
} */

.spinner-main {
  @apply flex 
  justify-center 
  items-center
  h-screen 
  w-full 
  m-0;
  background: radial-gradient(#cecece, #fff);
}
.spinner-container {
  @apply w-16 h-16 relative rounded-full;
  background: linear-gradient(165deg, #eb6e1e 0%, #eb6e1e 100%);
}
.spinner-loader:before {
  @apply absolute 
  content-[""] 
  w-full 
  h-full 
  shadow-[0_-10px_20px_20px_#ffffff40_inset,0_-5px_15px_10px_#ffffff50_inset,0_-2px_5px_#ffffff80_inset,0_-3px_2px_#ffffffbb_inset,0_2px_0px_#ffffff,0_2px_3px_#ffffff,0_5px_5px_#ffffff90,0_10px_15px_#ffffff60,0_10px_20px_20px_#ffffff40] 
  blur-sm
  animate-[2s_rotate_linear_infinite] 
  rounded-[100%] 
  border-b-0 
  border-b-[#ffffff05] 
  border-solid;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: relative;
  width: 4rem;
  height: 4rem;
  transform-style: preserve-3d;
  perspective: 800px;
}
.loading .arc {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-bottom: 3px solid red;
}
@keyframes rotate1 {
  from {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0);
  }

  to {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(1turn);
  }
}
@keyframes rotate2 {
  from {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0);
  }

  to {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(1turn);
  }
}
@keyframes rotate3 {
  from {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0);
  }

  to {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(1turn);
  }
}
