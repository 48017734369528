.custom-input {
  @apply rounded-md bg-white text-base;
  & .MuiInputBase-root {
    @apply bg-transparent !rounded-md;
  }
  & input {
    @apply rounded-md;
  }
  & .date-picker-button {
    & button {
      @apply !m-0;
    }
  }
}

.custom-input > .Mui-focused {
  @apply !rounded-md;
}

.custom-input .MuiInputLabel-root.Mui-focused {
  @apply !text-pami-orange-default;
}

.custom-input .MuiInputBase-root::after,
.custom-input .MuiInputBase-root:hover ::after,
.custom-input .MuiInputBase-root::before,
.custom-input .MuiInputBase-root:hover ::before {
  @apply !border-none;
}

.custom-input .MuiOutlinedInput-notchedOutline {
  @apply border-none;
}
.date-picker-popper {
  & .MuiPickersDay-root.Mui-selected {
    @apply !bg-pami-orange-default;
  }
  & .MuiPickersDay-root:hover {
    @apply bg-pami-orange-light;
  }
}

/*

@media (max-width: 768px) {
  .custom-input {
    @apply w-full;
  }
} */
