.custom-icon-button {
  @apply !h-12 
  !w-12 
  !flex 
  !items-center 
  !justify-center
  transition;

  /* !rounded-lg */
  & svg {
    @apply w-6 h-6 transition;
  }
  &:hover {
    @apply !bg-pami-orange-default transition;
  }
  &:hover svg {
    @apply !text-white transition;
  }
}
.MuiLoadingButton-root {
  @apply !font-bebas 
  !text-2xl 
  !mx-auto 
  !border-solid
  !rounded-full
!bg-white
  !px-8
  !border-4
  !h-12
  !text-pami-orange-default
  !border-pami-orange-default
  !duration-300
  !ease-[ease];
}
.MuiLoadingButton-loading,
.MuiLoadingButton-root:disabled {
  @apply grayscale
  !pointer-events-none;
}
.MuiLoadingButton-loading {
  @apply !text-transparent;
}

.MuiLoadingButton-root:hover {
  @apply !text-white 
  !bg-pami-orange-default
  !border-4 
  !border-solid 
  !border-pami-orange-default;
}
